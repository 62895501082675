import React, { useState } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

const dummyData = [
    {
      vehicle: 'AAA001ZN - SVR',
      devices: [
        {
          type: 'FleetTrack',
          ids: ['Device ID: 13458']
        },
        {
          type: 'VVT',
          ids: ['Device ID: 13459']
        }
      ]
    },
    {
      vehicle: 'AAA002ZN - SVR',
      devices: [
        {
          type: 'VVT-AI',
          ids: ['Device ID: 13460']
        }
      ]
    },
    {
      vehicle: 'AAA003ZN - AGILE',
      devices: [
        {
          type: 'VVT-AI',
          ids: ['Device ID: 13461']
        }
      ]
    },
    {
      vehicle: 'AAA004ZN - AGILE',
      devices: [
        {
          type: 'VVT-AI',
          ids: ['Device ID: 13461']
        }
      ]
    },
    {
      vehicle: 'AAA005ZN - SVR',
      devices: [
        {
          type: 'VVT-AI',
          ids: ['Device ID: 13461']
        }
      ]
    },
    {
      vehicle: 'AAA006ZN - AGILE',
      devices: [
        {
          type: 'VVT-AI',
          ids: ['Device ID: 13461']
        }
      ]
    },
    {
      vehicle: 'AAA007ZN - SVR',
      devices: [
        {
          type: 'VVT-AI',
          ids: ['Device ID: 13461']
        }
      ]
    },
    {
      vehicle: 'AAA008ZN - AGILE',
      devices: [
        {
          type: 'VVT-AI',
          ids: ['Device ID: 13461']
        }
      ]
    },
    {
      vehicle: 'AAA009ZN - SVR',
      devices: [
        {
          type: 'VVT-AI',
          ids: ['Device ID: 13461']
        }
      ]
    },
    {
      vehicle: 'AAA010ZN - AGILE',
      devices: [
        {
          type: 'VVT-AI',
          ids: ['Device ID: 13461']
        }
      ]
    },

  ];
  
  function ClientTree() {
    const [expanded, setExpanded] = useState([]);
  
    const handleToggle = (event, nodeIds) => {
      setExpanded(nodeIds);
    };
  
    const getItemId = (type, vehicleIndex, deviceIndex, idIndex) => {
      switch (type) {
        case 'vehicle':
          return `vehicle-${vehicleIndex}`;
        case 'device':
          return `device-${vehicleIndex}-${deviceIndex}`;
        case 'id':
          return `id-${vehicleIndex}-${deviceIndex}-${idIndex}`;
        default:
          return '';
      }
    };
  
    return (
      <SimpleTreeView
        expanded={expanded}
        onNodeToggle={handleToggle}
      >
        {dummyData.map((vehicleData, vehicleIndex) => (
          <TreeItem key={getItemId('vehicle', vehicleIndex)} itemId={getItemId('vehicle', vehicleIndex)} label={vehicleData.vehicle}>
            {vehicleData.devices.map((device, deviceIndex) => (
              <TreeItem key={getItemId('device', vehicleIndex, deviceIndex)} itemId={getItemId('device', vehicleIndex, deviceIndex)} label={device.type}>
                {device.ids.map((id, idIndex) => (
                  <TreeItem key={getItemId('id', vehicleIndex, deviceIndex, idIndex)} itemId={getItemId('id', vehicleIndex, deviceIndex, idIndex)} label={id} />
                ))}
              </TreeItem>
            ))}
          </TreeItem>
        ))}
      </SimpleTreeView>
    );
  }
  
  export default ClientTree;
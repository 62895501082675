import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../Features/Authentication/authSlice';
import { useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    Link,
    Grid,
    Paper,
    useTheme,
} from '@mui/material';
import FleetCam from '../Photos/FleetCam - Logo.png';
import LoginImage from '../Photos/Login_background.jpg';
import { isMobile } from 'react-device-detect';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { error } = useSelector((state) => state.auth);
    const theme = useTheme();

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login({ username, password }))
            .unwrap()
            .then((response) => {
                if (response.require_password_reset) {
                    navigate('/reset-password');
                } else {
                    navigate('/dashboard');
                }
            })
            .catch((err) => {
                console.error('Login failed:', err);
            });
    };

    const LoginForm = () => (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <Box sx={{ mr: 1 }}>
                            <i className="fas fa-envelope"></i>
                        </Box>
                    ),
                }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <Box sx={{ mr: 1 }}>
                            <i className="fas fa-lock"></i>
                        </Box>
                    ),
                }}
            />
            <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
            />
            {error && (
                <Typography color="error" variant="body2">
                    {error}
                </Typography>
            )}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Log in
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link href="/reset-password" variant="body2">
                    Forgot Password?
                </Link>
            </Box>
        </Box>
    );

    if (isMobile) {
        return (
            <Box sx={{ 
                minHeight: '100vh', 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center',
                backgroundImage: `url(${LoginImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                padding: theme.spacing(2),
            }}>
                <Paper 
                    elevation={6}
                    sx={{ 
                        width: '100%', 
                        maxWidth: '400px', 
                        padding: theme.spacing(4),
                        borderRadius: '16px',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        backdropFilter: 'blur(10px)',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                        <img src={FleetCam} alt="Company Logo" style={{ width: '70%', maxWidth: '250px' }} />
                    </Box>
                    <Typography component="h1" variant="h5" align="center" sx={{ mb: 3, fontWeight: 'bold', color: theme.palette.primary.main }}>
                        Log in to your Account
                    </Typography>
                    <LoginForm />
                </Paper>
            </Box>
        );
    }

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
                item
                xs={12}
                sm={8}
                md={6}
                component={Box}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ backgroundColor: 'white' }}
            >
                <Paper
                    elevation={6}
                    sx={{
                        padding: '40px',
                        borderRadius: '12px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: '400px',
                        width: '100%',
                    }}
                >
                    <img src={FleetCam} alt="Company Logo" style={{ width: '60%', marginBottom: '20px' }} />
                    <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
                        Log in to your Account
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 3 }}>
                        Welcome back! Please log in to continue.
                    </Typography>
                    <LoginForm />
                </Paper>
            </Grid>
            <Grid
                item
                xs={false}
                sm={4}
                md={6}
                sx={{
                    backgroundImage: `url(${LoginImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
        </Grid>
    );
};

export default Login;
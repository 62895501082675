import React, { useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { CssBaseline } from '@mui/material';
import GlobalRouter from './Global/Router';
import { Provider } from 'react-redux';
import { store } from './store';

function App() {
  const [mode, setMode] = useState('light');

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const muiTheme = useMemo(() => theme(mode), [mode]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <GlobalRouter toggleMode={toggleMode} />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
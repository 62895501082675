import React from 'react';
import { Box, Typography, Breadcrumbs, Link as MuiLink, Grid, Paper, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ManageCard from './ManageCard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DevicesIcon from '@mui/icons-material/Devices';

const SettingsPage = () => {
  const settingsCards = [
    { title: "User Management", icon: <PeopleIcon />, manageLink: "/manage-users", groupLink: "/user-groups", manageTitle: "Manage Users", groupTitle: "Manage User Groups" },
    { title: "Client Management", icon: <BusinessIcon />, manageLink: "/manage-clients", manageTitle: "Manage Clients" },
    { title: "Vehicle Management", icon: <DirectionsCarIcon />, manageLink: "/manage-vehicles", manageTitle: "Manage Vehicles" },
    { title: "Device Management", icon: <DevicesIcon />, manageLink: "/manage-devices", manageTitle: "Manage Devices" },
  ];

  return (
    <Box sx={{ p: 3, mt: 1 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 2 }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <MuiLink component={RouterLink} to="/" color="inherit">
            Home
          </MuiLink>
          <MuiLink component={RouterLink} to="/dashboard" color="inherit">
            Dashboard
          </MuiLink>
          <Typography color="textPrimary">Settings</Typography>
        </Breadcrumbs>

        <Box display="flex" alignItems="center" mb={2}>
          <IconButton sx={{ mr: 2, bgcolor: 'primary.light' }}>
            <DashboardIcon />
          </IconButton>
          <Typography variant="h4">
            Admin Settings
          </Typography>
        </Box>

        <Typography variant="body1" color="textSecondary" paragraph>
          Manage your system settings and configurations from this central dashboard.
        </Typography>
      </Paper>

      <Grid container spacing={2}>
        {settingsCards.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper elevation={2} sx={{ height: '100%' }}>
              <ManageCard
                title={card.title}
                icon={card.icon}
                manageLink={card.manageLink}
                groupLink={card.groupLink}
                manageTitle={card.manageTitle}
                groupTitle={card.groupTitle}
              />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SettingsPage;

import React, { useState } from 'react';
import { Box, Button, Stepper, Step, StepLabel, Select, MenuItem, FormControl, InputLabel, Dialog, DialogContent, DialogTitle } from '@mui/material';

const steps = ['Company Info', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6'];

const NewInstallWizard = ({ groups, open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState('');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Install Wizard</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 2, mb: 1, p: 2 }}>
            {activeStep === 0 && (
              <FormControl fullWidth>
                <InputLabel>Select Company</InputLabel>
                <Select value={selectedCompany} onChange={handleCompanyChange}>
                  {groups.map((group) => (
                    <MenuItem key={group.group_id} value={group.group_id}>
                      {group.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NewInstallWizard;

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, Card, CardMedia, Box } from '@mui/material';
import ReactPlayer from 'react-player';
import { styled } from '@mui/system';

const Thumbnail = styled('img')({
  width: '100%',
  height: 'auto',
  borderRadius: '8px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
});

const VideoCard = styled(Card)({
  marginBottom: '10px',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
});

const DetailContainer = styled(Box)({
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  padding: '16px',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  marginBottom: '16px',
});

const EventDetailModal = ({ open, onClose, event }) => {
  const [selectedMedia, setSelectedMedia] = useState(null);

  if (!event) return null;

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
  };

  const handleCloseMedia = () => {
    setSelectedMedia(null);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Event Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {[
            { label: 'Client Name', value: event.client_name },
            { label: 'Vehicle Plate Number', value: event.vehicle_plate_number },
            { label: 'Event Type', value: event.event_type },
            { label: 'Driver', value: event.driver },
            { label: 'Speed', value: event.speed },
            { label: 'Latitude', value: event.latitude },
            { label: 'Longitude', value: event.longitude },
            { label: 'Passenger Count', value: event.passenger_count },
            { label: 'Primary Contact', value: event.prim_contact ? 'Yes' : 'No' },
            { label: 'Secondary Contact', value: event.sec_contact ? 'Yes' : 'No' },
            { label: 'Emergency Contact', value: event.emergency_contact ? 'Yes' : 'No' },
            { label: 'Address', value: event.address },
            { label: 'Agent Comments', value: event.agent_comments },
          ].map((detail, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <DetailContainer>
                <Typography variant="h6">{detail.label}:</Typography>
                <Typography variant="body1" style={{ fontWeight: 'normal' }}>{detail.value}</Typography>
              </DetailContainer>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Media:</Typography>
            <Grid container spacing={2}>
              {event.media.map((media, index) => (
                <Grid item xs={6} sm={3} key={index}>
                  {media.image ? (
                    <Thumbnail
                      src={media.image}
                      alt={`Event media ${index + 1}`}
                      onClick={() => handleMediaClick(media)}
                    />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No Image Available
                    </Typography>
                  )}
                </Grid>
              ))}
              {event.media.map((media, index) => (
                <Grid item xs={6} sm={3} key={index}>
                  <VideoCard onClick={() => handleMediaClick(media)}>
                    <CardMedia>
                      <ReactPlayer url={media.video} controls width="100%" height="auto" />
                    </CardMedia>
                  </VideoCard>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>

      {selectedMedia && (
        <Dialog open={Boolean(selectedMedia)} onClose={handleCloseMedia} maxWidth="lg" fullWidth>
          <DialogContent>
            {selectedMedia.image && (
              <img src={selectedMedia.image} alt="Selected media" style={{ width: '100%' }} />
            )}
            {selectedMedia.video && (
              <ReactPlayer url={selectedMedia.video} controls width="100%" height="auto" />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMedia} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default EventDetailModal;
import React from 'react';
import { Grid, Box, Typography, Paper, useTheme, Card, CardContent, CardHeader } from '@mui/material';
import ClientTree from './ClientTree';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart'; // For area chart

export default function ExcoLandingPage() {
  const theme = useTheme();

  // Dummy data
  const donutChartData = [
    { label: 'Camera Obstruction', value: 1392 },
    { label: 'Camera Tampering', value: 684 },
    { label: 'Hazardous Observation', value: 316 },
    { label: 'Cellphone Usage', value: 197 },
    { label: 'Seatbelt Violation', value: 123 },
    { label: 'Smoking in Cab', value: 110 },
    { label: 'Severe Overspeeding', value: 25 },
  ];
  const driverRiskData = [
    { driver: 'Driver 1', rating: 1800 },
    { driver: 'Driver 2', rating: 500 },
    { driver: 'Driver 3', rating: 200 },
    { driver: 'Driver 4', rating: 150 },
  ];
  const areaChartData = [
    { month: 'March', value: 50 },
    { month: 'April', value: 60 },
    { month: 'May', value: 70 },
    { month: 'June', value: 100 },
    { month: 'July', value: 150 },
    { month: 'August', value: 250 },
    { month: 'September', value: 200 },
  ];
  const vehicleRiskData = [
    { vehicle: 'DN69ZGGP', risk: 220 },
    { vehicle: 'DT89HRGP', risk: 210 },
    { vehicle: 'DX28ZHGP', risk: 180 },
    { vehicle: 'FB86DRGP', risk: 160 },
    { vehicle: '14-F743VYGP', risk: 150 },
  ];

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, mt: -4, backgroundColor: theme.palette.background.default, overflow: 'hidden' }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom color="primary" fontWeight="bold">
          SA Metal JHB Dashboard
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          Welcome to your Dashboard. Monitor your fleet and their activities efficiently.
        </Typography>
      </Paper>

      <Grid container spacing={4} sx={{ overflow: 'hidden' }}>
        {/* First Row: TreeView and PieChart */}
        <Grid item xs={12} md={4} sx={{ height: '90%' }}>
          <Paper elevation={3} sx={{ p: 2, height: '100%', borderRadius: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom color="textPrimary">
              My Vehicles
            </Typography>
            <Box sx={{ flexGrow: 1, maxHeight: 'calc(100% - 40px)', overflowY: 'auto' }}>
              <ClientTree />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ height: '90%' }}>
          <Card elevation={3} sx={{ height: '100%' }}>
            <CardHeader
              title="Risk Profile by Events"
              titleTypographyProps={{ variant: 'h6' }}
              sx={{ backgroundColor: theme.palette.secondary.light }}
            />
            <CardContent>
              <PieChart
                series={[
                  {
                    data: donutChartData,
                    innerRadius: 60,
                    outerRadius: 120,
                    paddingAngle: 5,
                    cornerRadius: 8,
                    startAngle: -90,
                    endAngle: 270,
                  },
                ]}
                colors={[
                  theme.palette.primary.main,
                  theme.palette.secondary.main,
                  theme.palette.error.main,
                  theme.palette.warning.main,
                ]}
                height={300}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Second Row: Bar Chart (Driver Risk Rating) */}
        <Grid item xs={12} sx={{ height: '80%' }}>
          <Card elevation={3} sx={{ height: '100%' }}>
            <CardHeader
              title="Risk Rating per Driver (Top 20)"
              titleTypographyProps={{ variant: 'h6' }}
              sx={{ backgroundColor: theme.palette.primary.light }}
            />
            <CardContent>
              <BarChart
                dataset={driverRiskData}
                xAxis={[{ scaleType: 'band', dataKey: 'driver' }]} // Categorical axis
                yAxis={[{ scaleType: 'linear', dataKey: 'rating' }]} // Continuous axis
                series={[{ dataKey: 'rating', label: 'Rating' }]}
                colors={[theme.palette.primary.main]}
                layout="vertical" // Layout adjusted for band scale on x-axis
                height={300}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Third Row: Area Chart (Fleet Risk Profile) */}
        <Grid item xs={12} sx={{ height: '80%' }}>
          <Card elevation={3} sx={{ height: '100%' }}>
            <CardHeader
              title="Fleet Risk Profile (Past 12 Months)"
              titleTypographyProps={{ variant: 'h6' }}
              sx={{ backgroundColor: theme.palette.primary.light }}
            />
            <CardContent>
              <LineChart
                dataset={areaChartData}
                xAxis={[{ scaleType: 'band', dataKey: 'month' }]} // Categorical axis for months
                series={[{ dataKey: 'value', label: 'Risk Level', area: true }]}
                colors={[theme.palette.primary.main]}
                height={300}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Fourth Row: Bar Chart (Top 20 High Risk Vehicles) */}
        <Grid item xs={12} sx={{ height: '80%' }}>
          <Card elevation={3} sx={{ height: '100%' }}>
            <CardHeader
              title="Top 20 High-Risk Vehicles"
              titleTypographyProps={{ variant: 'h6' }}
              sx={{ backgroundColor: theme.palette.secondary.light }}
            />
            <CardContent>
              <BarChart
                dataset={vehicleRiskData}
                xAxis={[{ scaleType: 'band', dataKey: 'vehicle' }]} // Categorical axis for vehicles
                yAxis={[{ scaleType: 'linear', dataKey: 'risk' }]} // Continuous axis
                series={[{ dataKey: 'risk', label: 'Risk Level' }]}
                colors={[theme.palette.secondary.main]}
                layout="vertical" // Layout adjusted for band scale on x-axis
                height={300}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

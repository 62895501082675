import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Features/Authentication/authSlice';
import { apiSlice } from './Features/API/apiSlice'; // Import your apiSlice
import { authApiSlice } from './Features/API/authApiSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [apiSlice.reducerPath]: apiSlice.reducer, // Add the apiSlice reducer
    [authApiSlice.reducerPath]: authApiSlice.reducer, // Add the authApiSlice reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, authApiSlice.middleware), // Add the apiSlice and authA   piSlice middleware
});

export default store;

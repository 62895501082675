import axios from 'axios';

// Debug: Log the BASE URLs for both API and Auth
console.log('API Base URL:', process.env.REACT_APP_API_URL);
console.log('Auth Base URL:', process.env.REACT_APP_AUTH_URL);

// Create separate Axios instances for API and Auth
const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,  // Base URL for non-auth APIs
});

const authInstance = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL,  // Base URL for auth-related requests
});

// Add a request interceptor to the API instance to include the token in all requests
apiInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Token ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a request interceptor to the Auth instance (optional, if needed for Auth requests)
authInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Token ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Export separate functions for Auth and non-Auth requests
export const loginUser = (credentials) => {
    return authInstance.post('/login/', credentials);  
};

export default apiInstance;

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import image from '../Photos/404-notfound.avif';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/bureau-dashboard'); // Adjust the path as needed
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.default',
        color: 'text.primary',
        padding: 2,
        textAlign: 'center',
      }}
    >
      {/* Image at the Top */}
      <Box
        component="img"
        src={image}
        alt="404 Illustration"
        sx={{
          width: { xs: '60%', sm: '40%', md: '30%' },
          maxWidth: 300,
          height: 'auto',
          mb: 4,
        }}
      />

     

      {/* 404 Text */}
      <Typography variant="h4" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Oops! The page you're looking for doesn't exist.
      </Typography>

      {/* Navigation Button */}
      <Button variant="contained" color="primary" onClick={handleGoHome} sx={{ mt: 3 }}>
        Go to Dashboard
      </Button>
    </Box>
  );
};

export default NotFound;
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useCreateEventMutation } from '../../Features/API/apiSlice';

const eventTypes = [
  'Passenger Door',
  'Safe Door',
  'Driver Door',
  'Geofence Exit',
  'Geofence Entry',
  'Illegal Zone Entry',
  'Unauthorized Stop',
  'Over speeding',
  'Battery Tamper',
  'Security Box Tamper',
  'Panic Alarm',
  'Vehicle Breakdown',
  'Smoking in Cab',
  'Passenger(s) in Vehicle',
  'Seatbelt Violation',
  'Cellphone Usage',
  'Reckless Driving',
  'Camera Tampering',
  'Camera Obstruction',
  'Illegal Activity',
];

const CreateEventModal = ({ open, onClose, vehicle, groupTitle }) => {
  const [createEvent] = useCreateEventMutation();
  const [eventData, setEventData] = useState({
    client_name: groupTitle,
    plate_number: vehicle ? vehicle.plate_number : '',
    event_type: '',
    latitude: '',
    longitude: '',
    
    speed: '',
    images: [],
    videos: [],
    agent_comments: '',
    prim_contact: false,
    sec_contact: false,
    emergency_contact: false,
    address: '',
    driver: vehicle ? vehicle.driver : '',
    passenger_count: '',
  });

  useEffect(() => {
    if (vehicle && groupTitle) {
      setEventData(prevData => ({
        ...prevData,
        client_name: groupTitle,
        plate_number: vehicle.plate_number,
        driver: vehicle.driver || '',
      }));
    }
  }, [vehicle, groupTitle]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEventData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleMediaUpload = (e) => {
    const files = Array.from(e.target.files).slice(0, 4);
    const mediaType = e.target.name === 'uploaded_images' ? 'images' : 'videos';
    setEventData(prevData => ({ ...prevData, [mediaType]: files }));
  };

  const handleSubmit = async () => {
    try {
      // Create a new FormData object
      const formData = new FormData();

      // Append form fields
      Object.keys(eventData).forEach((key) => {
        if (key !== 'images' && key !== 'videos') {
          const value = eventData[key];
          // Convert booleans to strings
          formData.append(key, typeof value === 'boolean' ? value.toString() : value);
        }
      });

      // Append images
      eventData.images.forEach((file) => {
        formData.append('uploaded_images[]', file);
      });

      // Append videos
      eventData.videos.forEach((file) => {
        formData.append('uploaded_videos[]', file);
      });

      console.log('FormData payload being sent:', {
        plate_number: eventData.plate_number,
        images: formData.getAll('uploaded_images'),
        videos: formData.getAll('uploaded_videos'),
      });

      // Send the request
      const result = await createEvent(formData).unwrap();
      console.log('Event created successfully:', result);
      onClose();
    } catch (error) {
      console.error('Failed to create event:', error);
    }
  };

  if (!vehicle || !groupTitle) {
    return null; // Render nothing if the required props are not available
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create Event</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Client Name"
              name="client_name"
              value={eventData.client_name}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Vehicle Plate Number"
              name="plate_number"
              value={eventData.plate_number}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Event Type</InputLabel>
              <Select
                name="event_type"
                value={eventData.event_type}
                onChange={handleChange}
              >
                {eventTypes.map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Latitude"
              name="latitude"
              value={eventData.latitude}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Longitude"
              name="longitude"
              value={eventData.longitude}
              onChange={handleChange}
            />
          </Grid>
          
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Speed"
              name="speed"
              value={eventData.speed}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Passenger Count"
              name="passenger_count"
              value={eventData.passenger_count}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Upload Images (max 4)</Typography>
            <input
              type="file"
              name="uploaded_images"
              multiple
              accept="image/*"
              onChange={handleMediaUpload}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Upload Videos (max 4)</Typography>
            <input
              type="file"
              name="uploaded_videos"
              multiple
              accept="video/*"
              onChange={handleMediaUpload}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Agent Comments"
              name="agent_comments"
              value={eventData.agent_comments}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={eventData.prim_contact} onChange={handleChange} name="prim_contact" />}
              label="Primary Contact"
            />
            <FormControlLabel
              control={<Checkbox checked={eventData.sec_contact} onChange={handleChange} name="sec_contact" />}
              label="Secondary Contact"
            />
            <FormControlLabel
              control={<Checkbox checked={eventData.emergency_contact} onChange={handleChange} name="emergency_contact" />}
              label="Emergency Contact"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={eventData.address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Driver"
              name="driver"
              value={eventData.driver}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventModal;
import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, CircularProgress, Paper, Button } from '@mui/material';
import { useFetchEventsQuery, useFetchEventDetailsQuery } from '../../Features/API/apiSlice';
import EventDetailModal from './EventDetailModal';

export default function EventGrid() {
  const { data: events, error, isLoading } = useFetchEventsQuery();
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: eventDetails, isLoading: isDetailsLoading } = useFetchEventDetailsQuery(selectedEventId, {
    skip: !selectedEventId,
  });

  const handleViewMore = (eventId) => {
    setSelectedEventId(eventId);
    setIsModalOpen(true);
  };

  const columns = [
    { field: 'created_at', headerName: 'Timestamp', width: 150 },
    { field: 'client_name', headerName: 'Company/Client', width: 200 },
    { field: 'vehicle_plate_number', headerName: 'Vehicle Reg', width: 150 },
    { field: 'event_type', headerName: 'Event Type', width: 150 },
    { field: 'driver', headerName: 'Driver', width: 150 },
    { field: 'bureau_agent', headerName: 'Bureau Agent', width: 150 },
    {
      field: 'viewMore',
      headerName: '',
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" color="primary" onClick={() => handleViewMore(params.row.id)}>
          View More
        </Button>
      ),
    },
  ];

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error loading events</div>;
  }

  return (
    <>
      <Paper sx={{ borderRadius: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', height: '100%', width: '100%' }}>
        <DataGrid
          rows={events || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '& .MuiDataGrid-virtualScroller': {
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '10px',
                border: '2px solid #f1f1f1',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            },
          }}
        />
      </Paper>
      <EventDetailModal open={isModalOpen} onClose={() => setIsModalOpen(false)} event={eventDetails} isLoading={isDetailsLoading} />
    </>
  );
}
import { createTheme } from '@mui/material/styles';

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                // Palette values for light mode
                primary: {
                    main: '#1976d2',
                    light: '#63a4ff',
                    dark: '#004ba0',
                },
                secondary: {
                    main: '#dc004e',
                    light: '#ff5983',
                    dark: '#9a0036',
                },
                background: {
                    default: '#f4f6f8',
                    paper: '#ffffff',
                },
                text: {
                    primary: '#333333',
                    secondary: '#777777',
                },
            }
            : {
                // Palette values for dark mode
                primary: {
                    main: '#90caf9',
                },
                secondary: {
                    main: '#f48fb1',
                },
                background: {
                    default: '#121212',
                    paper: '#1d1d1d',
                },
                text: {
                    primary: '#ffffff',
                    secondary: '#bbbbbb',
                },
            }),
    },
    typography: {
        fontFamily: 'Roboto Flex, Arial, sans-serif',
        h4: {
            fontWeight: 600,
        },
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            fontWeight: 500,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                *::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }
                *::-webkit-scrollbar-track {
                    background: #f1f1f1;
                    border-radius: 8px;
                }
                *::-webkit-scrollbar-thumb {
                    background-color: #c8c8c8;
                    border-radius: 8px;
                }
                *::-webkit-scrollbar-thumb:hover {
                    background: #aaaaaa;
                }
            `,
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                },
            },
        },
    },
});

const theme = (mode) => createTheme(getDesignTokens(mode));

export default theme;
import React from 'react';
import ClientNavBar from './ClientNavBar';
import ClientSideNav from './ClientSideNav';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const ClientLayout = ({ toggleMode }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <ClientNavBar toggleMode={toggleMode} />
      <ClientSideNav />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default ClientLayout;

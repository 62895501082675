import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  useFetchAlertsQuery,
  useFetchDevicesForVehiclesQuery,
  useFetchDeviceGroupsQuery,
} from '../../Features/API/apiSlice';

const AlertsPage = () => {
  const {
    data: initialAlerts,
    error: alertsError,
    isLoading: isLoadingAlerts,
  } = useFetchAlertsQuery();
  const { data: vehiclesResponse, error: vehiclesError } = useFetchDevicesForVehiclesQuery();
  const { data: groups, error: groupsError } = useFetchDeviceGroupsQuery();

  const [alerts, setAlerts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const alertsQueue = useRef([]);

  useEffect(() => {
    if (initialAlerts) {
      const formattedAlerts = initialAlerts.map((alert) => ({
        id: alert.alert_id,
        ...alert,
        vehicle: alert.plate_number,
      }));
      setAlerts(formattedAlerts);
    }
  }, [initialAlerts]);

  useEffect(() => {
    const eventSource = new EventSource('https://fleetcamagile.com/api/alerts/stream/');

    eventSource.onopen = () => console.log('EventSource connection opened.');

    const handleNewAlert = (event) => {
      try {
        const newAlert = JSON.parse(event.data);

        const updatedAlert = {
          id: newAlert.alert_id,
          ...newAlert,
          vehicle: newAlert.plate_number,
        };

        // Push the new alert to the queue
        alertsQueue.current.push(updatedAlert);
      } catch (error) {
        console.error('Failed to parse incoming alert:', error);
      }
    };

    eventSource.addEventListener('message', handleNewAlert);

    const intervalId = setInterval(() => {
      if (alertsQueue.current.length > 0) {
        setAlerts((prevAlerts) => {
          const newAlerts = [...alertsQueue.current, ...prevAlerts];
          alertsQueue.current = []; // Clear the queue
          return newAlerts;
        });
      }
    }, 500); // Adjust the interval as needed

    eventSource.onerror = (error) => {
      console.error('EventSource encountered an error:', error);
    };

    return () => {
      eventSource.removeEventListener('message', handleNewAlert);
      eventSource.close();
      clearInterval(intervalId);
      console.log('EventSource connection closed.');
    };
  }, []);

  const groupedAlertsArray = useMemo(() => {
    const grouped = alerts.reduce((groups, alert) => {
      const key = alert.device_id;
      if (!groups[key]) {
        groups[key] = {
          name: alert.name,
          vehicle: alert.vehicle,
          device_id: alert.device_id,
          alerts: [],
        };
      }
      groups[key].alerts.push(alert);
      return groups;
    }, {});

    let groupedArray = Object.values(grouped);

    // Sort to keep the expanded accordion on top
    groupedArray.sort((a, b) => {
      if (expandedAccordion && a.device_id === expandedAccordion) return -1;
      if (expandedAccordion && b.device_id === expandedAccordion) return 1;
      // Sort by the timestamp of the latest alert
      const aLatest = new Date(a.alerts[0]?.timestamp).getTime() || 0;
      const bLatest = new Date(b.alerts[0]?.timestamp).getTime() || 0;
      return bLatest - aLatest;
    });

    return groupedArray;
  }, [alerts, expandedAccordion]);

  const paginatedAlerts = useMemo(() => {
    const start = currentPage * 5;
    const end = start + 5;
    return groupedAlertsArray.slice(start, end);
  }, [groupedAlertsArray, currentPage]);

  const columns = useMemo(() => [
    { field: 'timestamp', headerName: 'Timestamp', width: 200 },
    { field: 'message', headerName: 'Alert Type', width: 150 },
    // ... other columns as needed
  ], []);

  const getRowClassName = useCallback((params) => {
    const alertName = params.row.name.toLowerCase();
    if (/ignition off/i.test(alertName) || /security box/i.test(alertName) || /panic/i.test(alertName)) {
      return 'alert-p1'; // P1 alerts
    } else if (/zone in/i.test(alertName) || /zone out/i.test(alertName) || /overspeed/i.test(alertName)) {
      return 'alert-p2'; // P2 alerts
    } else if (/crash/i.test(alertName) || /door/i.test(alertName) || /ignition on/i.test(alertName)) {
      return 'alert-p3'; // P3 alerts
    }
    return '';
  }, []);

  if (isLoadingAlerts) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (alertsError || vehiclesError || groupsError) {
    return (
      <Typography color="error">
        Error fetching data: {alertsError?.message || vehiclesError?.message || groupsError?.message}
      </Typography>
    );
  }

  return (
    <Paper sx={{ borderRadius: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
      <Box sx={{ p: 3, mt: 10 }}>
        <Typography variant="h4" gutterBottom>
          Alerts
        </Typography>
        {paginatedAlerts.map((group) => (
          <AlertAccordion
            key={group.device_id}
            group={group}
            columns={columns}
            getRowClassName={getRowClassName}
            expandedAccordion={expandedAccordion}
            setExpandedAccordion={setExpandedAccordion}
          />
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            disabled={currentPage === 0}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={(currentPage + 1) * 5 >= groupedAlertsArray.length}
            onClick={() => setCurrentPage((prev) => prev + 1)}
            sx={{ ml: 2 }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

const AlertAccordion = React.memo(({ group, columns, getRowClassName, expandedAccordion, setExpandedAccordion }) => {
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAlert, setSelectedAlert] = useState(null);

  const handleSelectAll = useCallback((event) => {
    if (event.target.checked) {
      setSelectedAlerts(group.alerts.map((alert) => alert.id));
    } else {
      setSelectedAlerts([]);
    }
  }, [group.alerts]);

  const handleSelectAlert = useCallback((id) => {
    setSelectedAlerts((prev) =>
      prev.includes(id) ? prev.filter((alertId) => alertId !== id) : [...prev, id]
    );
  }, []);

  const handleMenuOpen = useCallback((event, alert) => {
    setAnchorEl(event.currentTarget);
    setSelectedAlert(alert);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setSelectedAlert(null);
  }, []);

  const handleDiscardAlert = useCallback(() => {
    // Implement discard logic here
    handleMenuClose();
  }, [handleMenuClose]);

  const handleHandleAlert = useCallback(() => {
    // Implement handle logic here
    handleMenuClose();
  }, [handleMenuClose]);

  const isExpanded = expandedAccordion === group.device_id;

  const memoizedRows = useMemo(() => group.alerts, [group.alerts]);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => setExpandedAccordion(isExpanded ? null : group.device_id)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`panel-${group.device_id}`}
      >
        <Checkbox
          checked={selectedAlerts.length === group.alerts.length}
          indeterminate={selectedAlerts.length > 0 && selectedAlerts.length < group.alerts.length}
          onChange={handleSelectAll}
        />
        <Typography variant="subtitle1">
          {group.name} - {group.vehicle} - {group.device_id}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={memoizedRows}
            columns={useMemo(() => [
              ...columns,
              {
                field: 'actions',
                headerName: 'Actions',
                width: 150,
                renderCell: (params) => (
                  <div>
                    <Checkbox
                      checked={selectedAlerts.includes(params.row.id)}
                      onChange={() => handleSelectAlert(params.row.id)}
                    />
                    <IconButton onClick={(event) => handleMenuOpen(event, params.row)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl && selectedAlert?.id === params.row.id)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleHandleAlert} disabled={selectedAlerts.length > 1}>
                        Handle Alert
                      </MenuItem>
                      <MenuItem onClick={handleDiscardAlert}>Discard Alert</MenuItem>
                    </Menu>
                  </div>
                ),
              },
            ], [columns, selectedAlerts, handleSelectAlert, handleMenuOpen, anchorEl, selectedAlert, handleMenuClose, handleHandleAlert, handleDiscardAlert])}
            pageSize={5}
            getRowClassName={getRowClassName}
            disableSelectionOnClick
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '10px',
                  border: '2px solid #f1f1f1',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555',
                },
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              '& .MuiDataGrid-row.alert-p1': {
                bgcolor: 'error.light',
                color: 'white',
                '&:hover': {
                  bgcolor: 'error.main',
                  color: 'black',
                },
              },
              '& .MuiDataGrid-row.alert-p2': {
                bgcolor: 'warning.light',
                color: 'white',
                '&:hover': {
                  bgcolor: 'warning.main',
                  color: 'black',
                },
              },
              '& .MuiDataGrid-row.alert-p3': {
                bgcolor: 'info.light',
                color: 'white',
                '&:hover': {
                  bgcolor: 'info.main',
                  color: 'black',
                },
              },
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
});

export default AlertsPage;

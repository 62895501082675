import React, { useState } from "react";
import { Drawer, List, ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import { styled } from "@mui/system";
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import ReportIcon from '@mui/icons-material/Report';
import HelpIcon from '@mui/icons-material/Help';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';

// StyledListItemIcon from SideNav.js
const StyledListItemIcon = styled(ListItemIcon)({
  color: "#000", // Default icon color
  transition: "color 0.3s ease",
  "&:hover": {
    color: "#0078B7", // Change icon color on hover
  },
});

const Sidebar = styled(Drawer)(({ theme, open }) => ({
  width: open ? 240 : 60,
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
  overflowX: "hidden",
  zIndex: 1000,
  boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
  "& .MuiDrawer-paper": {
    width: open ? 240 : 60,
    overflowX: "hidden",
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
  },
}));

const ClientSideNav = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <Sidebar
      variant="permanent"
      open={open}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <List style={{ paddingTop: 80 }}>
        <ListItemButton onClick={() => navigate('/exco-dashboard')}>
          <StyledListItemIcon>
            <DashboardIcon />
          </StyledListItemIcon>
          {open && <ListItemText primary="Dashboard" />}
        </ListItemButton>
        <ListItemButton>
          <StyledListItemIcon>
            <DirectionsCarIcon />
          </StyledListItemIcon>
          {open && <ListItemText primary="My Vehicles" />}
        </ListItemButton>
        <ListItemButton>
          <StyledListItemIcon>
            <PeopleIcon />
          </StyledListItemIcon>
          {open && <ListItemText primary="My Drivers" />}
        </ListItemButton>
        <ListItemButton>
          <StyledListItemIcon>
            <EventIcon />
          </StyledListItemIcon>
          {open && <ListItemText primary="Event Management" />}
        </ListItemButton>
        <ListItemButton>
          <StyledListItemIcon>
            <ReportIcon />
          </StyledListItemIcon>
          {open && <ListItemText primary="Reports" />}
        </ListItemButton>
        <ListItemButton>
          <StyledListItemIcon>
            <HelpIcon />
          </StyledListItemIcon>
          {open && <ListItemText primary="Help Center" />}
        </ListItemButton>
        <ListItemButton>
          <StyledListItemIcon>
            <SettingsIcon />
          </StyledListItemIcon>
          {open && <ListItemText primary="Settings" />}
        </ListItemButton>
      </List>
    </Sidebar>
  );
};

export default ClientSideNav;

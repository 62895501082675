import React from 'react';
import { Grid, Box, Typography, Paper, useTheme, Card, CardContent, CardHeader } from '@mui/material';
import VehicleTreeView from './TreeViewComp';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';

export default function BureauLandingPage() {
  const theme = useTheme();

  // Dummy data for Bar Chart
  const barChartData = [
    { client: 'Client1', value: 400 },
    { client: 'Client2', value: 300 },
    { client: 'Client3', value: 500 },
    { client: 'Client4', value: 200 },
    { client: 'Client5', value: 600 },
  ];

  // Dummy data for Pie Chart
  const pieChartData = [
    { label: 'Group A', value: 400 },
    { label: 'Group B', value: 300 },
    { label: 'Group C', value: 200 },
    { label: 'Group D', value: 100 },
  ];

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, mt: -4, backgroundColor: theme.palette.background.default,  overflow: 'hidden' }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom color="primary" fontWeight="bold">
          Bureau Dashboard
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          Welcome to the Bureau Dashboard. Monitor your clients and their activities efficiently.
        </Typography>
      </Paper>

      <Grid container spacing={4} sx={{  overflow: 'hidden' }}>
        {/* Left side: TreeView */}
        <Grid item xs={12} md={5} sx={{ height: '90%' }}>
          <Paper elevation={3} sx={{ p: 2, height: '100%', borderRadius: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom color="textPrimary">
              Clients and Vehicles
            </Typography>
            <Box sx={{ flexGrow: 1, maxHeight: 'calc(100% - 40px)', overflowY: 'auto' }}>
              <VehicleTreeView />
            </Box>
          </Paper>
        </Grid>

        {/* Right side: Charts */}
        <Grid item xs={12} md={7} sx={{ height: '100%' }}>
          <Grid container spacing={4} sx={{ height: '100%' }}>
            {/* Bar Chart */}
            <Grid item xs={12} md={6} sx={{ height: '80%' }}>
              <Card elevation={3} sx={{ height: '80%' }}>
                <CardHeader
                  title="Client Alerts"
                  titleTypographyProps={{ variant: 'h6' }}
                  sx={{ backgroundColor: theme.palette.primary.light }}
                />
                <CardContent>
                  <BarChart
                    dataset={barChartData}
                    xAxis={[{ scaleType: 'band', dataKey: 'client' }]}
                    series={[{ dataKey: 'value', label: 'Alerts' }]}
                    colors={[theme.palette.primary.main]}
                    layout="vertical"
                    height={300}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Pie Chart */}
            <Grid item xs={12} md={6} sx={{ height: '80%' }}>
              <Card elevation={3} sx={{ height: '80%' }}>
                <CardHeader
                  title="Group Distribution"
                  titleTypographyProps={{ variant: 'h6' }}
                  sx={{ backgroundColor: theme.palette.secondary.light }}
                />
                <CardContent>
                  <PieChart
                    series={[
                      {
                        data: pieChartData,
                        innerRadius: 60,
                        outerRadius: 120,
                        paddingAngle: 5,
                        cornerRadius: 8,
                        startAngle: -90,
                        endAngle: 270,
                      },
                    ]}
                    colors={[
                      theme.palette.primary.main,
                      theme.palette.secondary.main,
                      theme.palette.error.main,
                      theme.palette.warning.main,
                    ]}
                    height={300}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
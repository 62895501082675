import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ManageCard = ({ title, icon, manageLink, groupLink, manageTitle, groupTitle }) => {
  const linkStyle = {
    textDecoration: 'none',
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: 'blue',
    },
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box display="flex" alignItems="center" mb={2}>
          {icon}
          <Typography variant="h6" component="div" sx={{ ml: 1 }}>
            {title}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography
            component={RouterLink}
            to={manageLink}
            sx={linkStyle}
          >
            {manageTitle}
          </Typography>
          {groupLink && (
            <Typography
              component={RouterLink}
              to={groupLink}
              sx={linkStyle}
            >
              {groupTitle}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ManageCard;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for your authentication backend
const AUTH_BASE_URL = process.env.REACT_APP_AUTH_URL;

export const authApiSlice = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: AUTH_BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['User', 'UserGroup'],
  endpoints: (builder) => ({
    fetchUsers: builder.query({
      query: () => '/users/',
      providesTags: ['User'],
    }),
    createUser: builder.mutation({
      query: (userData) => ({
        url: '/users/',
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation({
      query: ({ userId, ...userData }) => ({
        url: `/users/${userId}/`,
        method: 'PUT',
        body: userData,
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/users/${userId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    fetchUserGroups: builder.query({
      query: () => '/groups/',
      providesTags: ['UserGroup'],
    }),
    createUserGroup: builder.mutation({
      query: (groupData) => ({
        url: '/groups/',
        method: 'POST',
        body: groupData,
      }),
      invalidatesTags: ['UserGroup'],
    }),
    updateUserGroup: builder.mutation({
      query: ({ groupId, ...groupData }) => ({
        url: `/groups/${groupId}/`,
        method: 'PUT',
        body: groupData,
      }),
      invalidatesTags: ['UserGroup'],
    }),
    deleteUserGroup: builder.mutation({
      query: (groupId) => ({
        url: `/groups/${groupId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserGroup'],
    }),
    fetchPermissions: builder.query({
      query: () => '/permissions/',
      providesTags: ['Permission'],
    }),
  }),
});

export const {
  useFetchUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useFetchUserGroupsQuery,
  useCreateUserGroupMutation,
  useUpdateUserGroupMutation,
  useDeleteUserGroupMutation,
  useFetchPermissionsQuery,
} = authApiSlice;
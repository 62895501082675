import React from 'react';
import Navbar from './NavBar';
import SideNav from './SideNav';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const BureauPortalLayout = ({ toggleMode }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar toggleMode={toggleMode} />
      <SideNav />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default BureauPortalLayout;
import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Checkbox,
    FormControlLabel,
    Breadcrumbs,
    Link as MuiLink,
    IconButton,
    MenuItem,
    Chip,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    ListItemText,
    Paper,
    Grid,
    Tooltip,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
    useFetchUserGroupsQuery,
    useCreateUserGroupMutation,
    useUpdateUserGroupMutation,
    useDeleteUserGroupMutation,
    useFetchUsersQuery,
    useFetchPermissionsQuery
} from '../../Features/API/authApiSlice';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Link as RouterLink } from 'react-router-dom';

const UserGroups = () => {
    const { data: groups = [], refetch: fetchGroups } = useFetchUserGroupsQuery();
    const [createUserGroup] = useCreateUserGroupMutation();
    const [updateUserGroup] = useUpdateUserGroupMutation();
    const [deleteUserGroup] = useDeleteUserGroupMutation();
    const { data: users = [] } = useFetchUsersQuery();
    const { data: permissions = [] } = useFetchPermissionsQuery();
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [currentGroupId, setCurrentGroupId] = useState(null);

    const handleTogglePermission = (permissionId) => {
        setSelectedPermissions(prevSelected =>
            prevSelected.includes(permissionId)
                ? prevSelected.filter(id => id !== permissionId)
                : [...prevSelected, permissionId]
        );
    };

    const handleSelectAllPermissions = (e) => {
        if (e.target.checked) {
            setSelectedPermissions(permissions.map(p => p.id));
        } else {
            setSelectedPermissions([]);
        }
    };

    const handleAddGroup = () => {
        setEditMode(false);
        setGroupName('');
        setSelectedPermissions([]);
        setOpen(true);
    };

    const handleEditGroup = (group) => {
        setEditMode(true);
        setGroupName(group.name);
        setSelectedPermissions(group.permissions.map(p => p.id));
        setCurrentGroupId(group.id);
        setOpen(true);
    };

    const handleSaveGroup = async () => {
        const groupData = { name: groupName, permissions: selectedPermissions };
        if (editMode) {
            await updateUserGroup({ groupId: currentGroupId, ...groupData });
        } else {
            await createUserGroup(groupData);
        }
        fetchGroups();
        setOpen(false);
    };

    const handleDeleteGroup = async (groupId) => {
        await deleteUserGroup(groupId);
        fetchGroups();
    };

    const getUserCount = (groupId) => {
        return users.filter(user => user.groups.includes(groupId)).length;
    };

    const columns = [
        { field: 'name', headerName: 'Group Name', flex: 1 },
        {
            field: 'userCount',
            headerName: 'Users',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={`${getUserCount(params.row.id)} users in this group`}>
                    <Chip label={getUserCount(params.row.id)} color="primary" />
                </Tooltip>
            ),
        },
        {
            field: 'permissions',
            headerName: 'Permissions',
            flex: 2,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {params.row.permissions.slice(0, 3).map((permission, index) => (
                        <Chip key={index} label={permission.name} size="small" />
                    ))}
                    {params.row.permissions.length > 3 && (
                        <Tooltip title={params.row.permissions.slice(3).map(p => p.name).join(', ')}>
                            <Chip label={`+${params.row.permissions.length - 3}`} size="small" />
                        </Tooltip>
                    )}
                </Box>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <IconButton onClick={() => handleEditGroup(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteGroup(params.row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ),
        },
    ];

    const rows = groups.map((group) => ({
        id: group.id,
        name: group.name,
        permissions: group.permissions,
    }));

    return (
        <Paper sx={{ borderRadius: '16px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ p: 3, mt: 7 }}>
                <Grid container spacing={3} alignItems="center" sx={{ mb: 3 }}>
                    <Grid item xs={12} md={6}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <MuiLink component={RouterLink} to="/" color="inherit">
                                Home
                            </MuiLink>
                            <MuiLink component={RouterLink} to="/dashboard" color="inherit">
                                Dashboard
                            </MuiLink>
                            <MuiLink component={RouterLink} to="/settings" color="inherit">
                                Settings
                            </MuiLink>
                            <Typography color="textPrimary">Manage User Groups</Typography>
                        </Breadcrumbs>
                        <Typography variant="h4" sx={{ mt: 2 }}>
                            Manage User Groups
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddGroup}
                            startIcon={<GroupAddIcon />}
                        >
                            Create Group
                        </Button>
                    </Grid>
                </Grid>

                <Paper elevation={3} sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10, 20]}
                        disableSelectionOnClick
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        sx={{
                            '& .MuiDataGrid-toolbarContainer': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                padding: 1,
                            },
                        }}
                    />
                </Paper>

                <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                    <DialogTitle>{editMode ? 'Edit User Group' : 'Create User Group'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Group Name"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            fullWidth
                            sx={{ mb: 2, mt: 2 }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedPermissions.length === permissions.length}
                                    indeterminate={selectedPermissions.length > 0 && selectedPermissions.length < permissions.length}
                                    onChange={handleSelectAllPermissions}
                                />
                            }
                            label="Select All Permissions"
                        />
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="permissions-select-label">Permissions</InputLabel>
                            <Select
                                labelId="permissions-select-label"
                                multiple
                                value={selectedPermissions}
                                onChange={(e) => setSelectedPermissions(e.target.value)}
                                input={<OutlinedInput label="Permissions" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={permissions.find(p => p.id === value)?.name} />
                                        ))}
                                    </Box>
                                )}
                            >
                                {permissions.map((permission) => (
                                    <MenuItem key={permission.id} value={permission.id}>
                                        <Checkbox checked={selectedPermissions.includes(permission.id)} />
                                        <ListItemText primary={permission.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleSaveGroup} color="primary" variant="contained">
                            {editMode ? 'Save Changes' : 'Create Group'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Paper>
    );
};

export default UserGroups;